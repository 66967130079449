import {
  ApproveCommentRequest,
  CommentListParams,
  ParamsCommentChange,
  ParamsCommentCreate,
} from 'src/@types/comment';
import { createAppAsyncThunk } from 'src/utils/createAppAsyncThunk';
import { hasError } from './slice';

export const getCommentListThunk = createAppAsyncThunk(
  'comment/getCommentList',
  async (params: CommentListParams, { dispatch, rejectWithValue, extra }) => {
    try {
      const response = await extra.api.getCommentList(params);
      // const response = await ApiClients.axiosComment.get('/comments', { params });

      return response.data;
    } catch (error) {
      dispatch(hasError(error));

      return rejectWithValue(error);
    }
  }
);

export const getCommentThunk = createAppAsyncThunk(
  'comment/getComment',
  async (id: number, { dispatch, rejectWithValue, extra }) => {
    try {
      const response = await extra.api.getCommentById(id);

      return response.data.data;
    } catch (error) {
      dispatch(hasError(error));

      return rejectWithValue(error);
    }
  }
);

export const editCommentThunk = createAppAsyncThunk(
  'comment/editComment',
  async (
    { id, ...params }: ParamsCommentChange,
    { dispatch, getState, rejectWithValue, extra }
  ) => {
    try {
      const response = await extra.api.editComment({ id, ...params });

      dispatch(getCommentListThunk(getState().comment.commentListParams));

      return response.data.data;
    } catch (error) {
      dispatch(hasError(error));

      return rejectWithValue(error);
    }
  }
);

export const createCommentThunk = createAppAsyncThunk(
  'comment/createComment',
  async (
    { answer, params }: { answer?: string; params: ParamsCommentCreate },
    { dispatch, rejectWithValue, extra }
  ) => {
    try {
      const response = (await extra.api.createComment(params)).data.data;

      if (answer) {
        await extra.api.createComment({
          group_id: response.group_id,
          parent_id: response.id,
          text: answer,
        });
      }

      return response;
    } catch (error) {
      dispatch(hasError(error));

      return rejectWithValue(error);
    }
  }
);

export const approveCommentThunk = createAppAsyncThunk(
  'comment/approveComment',
  async ({ ids, status }: ApproveCommentRequest, { rejectWithValue, extra }) => {
    try {
      const response = await extra.api.changeCommentStatus({
        ids,
        status,
      });

      return response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteCommentThunk = createAppAsyncThunk(
  'comment/deleteComment',
  async (ids: number[], { dispatch, rejectWithValue, extra }) => {
    try {
      const response = await extra.api.deleteComment(ids);

      return response.data.data;
    } catch (error) {
      dispatch(hasError(error));
      return rejectWithValue(error);
    }
  }
);

export const getCommentGroupsThunk = createAppAsyncThunk(
  'comment/getCommentGroups',
  async (_, { dispatch, rejectWithValue, extra }) => {
    try {
      const response = await extra.api.getCommentGroups();

      return response.data.data;
    } catch (error) {
      dispatch(hasError(error));

      return rejectWithValue(error);
    }
  }
);
