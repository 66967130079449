import { createSlice } from '@reduxjs/toolkit';
import { ClientState } from '../../@types/client';

const initialState: ClientState = {
  isLoading: false,
  clientList: {},
  clientListParams: {}
};

const slice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    resetError(state) {
      state.error = undefined;
    },

    getClientListSuccess(state, action) {
      state.isLoading = false;
      state.clientList = action.payload;
    },

    getClientSuccess(state, action) {
      state.isLoading = false;
      state.client = action.payload;
    },

    setClientListParams(state, action){
      state.clientListParams = action.payload;
    }
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading, hasError, resetError, getClientListSuccess, getClientSuccess, setClientListParams
} = slice.actions;

