import { createSlice } from '@reduxjs/toolkit';
import { CountryState } from '../../@types/country';

const initialState: CountryState = {
  isLoading: false,
  countryList: {},
  countryListParams: {},
  country: null,
};

const slice = createSlice({
  name: 'country',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    resetError(state) {
      state.error = undefined;
    },

    getCountryListSuccess(state, action) {
      state.isLoading = false;
      state.countryList = action.payload;
    },

    setCountryListParams(state, action) {
      state.countryListParams = action.payload;
    },

    getCountry(state, action) {
      state.isLoading = false;
      state.country = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  hasError,
  resetError,
  getCountryListSuccess,
  getCountry,
  setCountryListParams,
} = slice.actions;
