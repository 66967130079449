import { createSlice } from '@reduxjs/toolkit';
import { VideoState } from '../../@types/video';

const initialState: VideoState = {
  isLoading: false,
  videoList: [],
};

const slice = createSlice({
  name: 'video',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    resetError(state) {
      state.error = undefined;
    },

    getVideoListSuccess(state, action) {
      state.isLoading = false;
      state.videoList = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading, hasError, resetError, getVideoListSuccess
} = slice.actions;
