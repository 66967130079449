import { createSlice } from '@reduxjs/toolkit';
import { OrderState } from '../../@types/order';

const initialState: OrderState = {
  isLoading: false,
  orderList: {},
  orderListParams: {},
};

const slice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    resetError(state) {
      state.error = undefined;
    },

    getOrderListSuccess(state, action) {
      state.isLoading = false;
      state.orderList = action.payload;
    },

    getOrderSuccess(state, action) {
      state.isLoading = false;
      state.order = action.payload;
    },

    setOrderListParams(state, action) {
      state.orderListParams = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  hasError,
  resetError,
  getOrderListSuccess,
  getOrderSuccess,
  setOrderListParams,
} = slice.actions;
