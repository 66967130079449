import { m } from 'framer-motion';
import React, { FC } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { IconButton, ListItemText, ListItem } from '@mui/material';
// utils
import { fData } from '../../../utils/formatNumber';
// type
import { CustomFile } from '../type';
//
import Image from '../../Image';
import Iconify from '../../Iconify';
import { varFade } from '../../animate';

// ----------------------------------------------------------------------

interface IProps {
  showPreview: boolean;
  file: CustomFile | File;
  preview: string | undefined;
  onRemove: ((file: CustomFile | File) => void) | undefined;
  name: string | undefined;
  size: number | undefined;
}

const EmptyFile: FC<IProps> = ({ showPreview, file, preview, onRemove, name, size }) => (
  <>
    {showPreview && (
      <ListItem
        component={m.div}
        {...varFade().inRight}
        sx={{
          p: 0,
          m: 0.5,
          width: 80,
          height: 80,
          borderRadius: 1.25,
          overflow: 'hidden',
          position: 'relative',
          display: 'inline-flex',
          border: (theme) => `solid 1px ${theme.palette.divider}`,
        }}
      >
        <Image alt="preview" src={preview} ratio="1/1" />

        {onRemove && (
          <IconButton
            size="small"
            onClick={() => onRemove(file)}
            sx={{
              top: 6,
              p: '2px',
              right: 6,
              position: 'absolute',
              color: 'common.white',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
              '&:hover': {
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
              },
            }}
          >
            <Iconify icon={'eva:close-fill'} />
          </IconButton>
        )}
      </ListItem>
    )}
    {!showPreview && (
      <ListItem
        component={m.div}
        {...varFade().inRight}
        sx={{
          my: 1,
          px: 2,
          py: 0.75,
          borderRadius: 0.75,
          border: (theme) => `solid 1px ${theme.palette.divider}`,
        }}
      >
        <Iconify
          icon={'eva:file-fill'}
          sx={{ width: 28, height: 28, color: 'text.secondary', mr: 2 }}
        />

        <ListItemText
          primary={typeof file === 'string' ? file : name}
          secondary={typeof file === 'string' ? '' : fData(size || 0)}
          primaryTypographyProps={{ variant: 'subtitle2' }}
          secondaryTypographyProps={{ variant: 'caption' }}
        />

        {onRemove && (
          <IconButton edge="end" size="small" onClick={() => onRemove(file)}>
            <Iconify icon={'eva:close-fill'} />
          </IconButton>
        )}
      </ListItem>
    )}
  </>
);

export default EmptyFile;
