import { PayloadAction, createSlice, isPending, isRejectedWithValue } from '@reduxjs/toolkit';
import { AnalyticsService, AnalyticsServiceList, AnalyticsState } from 'src/@types/analytics';
import { getAdministrationHistoryListThunk } from '../thunks/analytics';

const initialState: AnalyticsState = {
  isLoading: false,
  analyticsAppList: {},
  analyticsAppListParams: {},
  analyticsPathList: {},
  analyticsPathListParams: {},
  analyticsServiceList: {},
  analyticsServiceListParams: {},
  administrationHistoryList: {},
};

const slice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    resetError(state) {
      state.error = undefined;
    },
    getAnalyticsAppList(state, action) {
      state.isLoading = false;
      state.analyticsAppList = action.payload;
    },
    setAnalyticsAppParams(state, action) {
      state.isLoading = false;
      state.analyticsAppListParams = action.payload;
    },
    setAnalyticsServiceParams(state, action) {
      state.isLoading = false;
      state.analyticsServiceListParams = action.payload;
    },
    setAnalyticsPathParams(state, action) {
      state.isLoading = false;
      state.analyticsPathListParams = action.payload;
    },
    getAnalyticsServiceList(state, action) {
      state.isLoading = false;
      state.analyticsServiceList = action.payload;
    },
    getAnalyticsPathList(state, action) {
      state.isLoading = false;
      state.analyticsPathList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getAdministrationHistoryListThunk.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          state.isLoading = false;
          state.administrationHistoryList = payload;
        }
      )
      //getAdministrationHistoryListThunk
      .addMatcher(isPending(getAdministrationHistoryListThunk), (state) => {
        // state.administrationHistoryList.error = '';
      })
      .addMatcher(isRejectedWithValue(getAdministrationHistoryListThunk), (state, { payload }) => {
        // state.administrationHistoryList.error = payload;
      });
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  hasError,
  resetError,
  getAnalyticsAppList,
  getAnalyticsServiceList,
  getAnalyticsPathList,
  setAnalyticsPathParams,
  setAnalyticsServiceParams,
  setAnalyticsAppParams,
} = slice.actions;
