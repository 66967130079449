import { Box, Typography, Stack } from '@mui/material';

import { UploadIllustration } from '../../assets';

const BlockContent = () => (
  <Stack
    spacing={2}
    alignItems="center"
    justifyContent="center"
    direction={{ xs: 'column', md: 'row' }}
    sx={{ width: 1, textAlign: { xs: 'center', md: 'left' } }}
  >
    <UploadIllustration sx={{ width: 220 }} />

    <Box sx={{ p: 3 }}>
      <Typography gutterBottom variant="h5">
        Выберите или перетащите файлы
      </Typography>

      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        Перетащите файлы сюда или кликните&nbsp;
        <Typography
          variant="body2"
          component="span"
          sx={{ color: 'primary.main', textDecoration: 'underline' }}
        >
          тут
        </Typography>
        &nbsp;чтобы выбрать файл
      </Typography>
    </Box>
  </Stack>
);

export default BlockContent;
