import { useParams, useLocation } from 'react-router-dom';
// @mui
import { Container } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
// sections
import { useDispatch } from '../../redux/store';
import { useEffect } from 'react';
import ConstantNewEditForm from 'src/sections/constant/ConstantNewEditForm';
import { getConstantThunk } from 'src/redux/thunks/constant';
// ----------------------------------------------------------------------

export default function ConstantCreate() {
  const dispatch = useDispatch();
  const { themeStretch } = useSettings();
  const { pathname } = useLocation();
  const { id = '' } = useParams();

  const isEdit = pathname.includes('edit');

  useEffect(() => {
    if (id) dispatch(getConstantThunk(Number(id)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Page title="Константы">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Константы"
          links={[
            { name: 'Главная', href: PATH_DASHBOARD.root },
            { name: 'Константы', href: PATH_DASHBOARD.constant.root },
            { name: 'Список' },
          ]}
        />

        <ConstantNewEditForm isEdit={isEdit} />
      </Container>
    </Page>
  );
}
