import { createSlice } from '@reduxjs/toolkit';
import { ConstantState } from 'src/@types/constant';

const initialState: ConstantState = {
  isLoading: false,
  constantList: {},
  constantListParams: {},
  constant: null,
};

const slice = createSlice({
  name: 'constant',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    resetError(state) {
      state.error = undefined;
    },

    getConstantsList(state, action) {
      state.isLoading = false;
      state.constantList = action.payload;
    },

    getConstant(state, action) {
      state.isLoading = false;
      state.constant = action.payload;
    },

    setConstantListParams(state, action) {
      state.constantListParams = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  hasError,
  resetError,
  getConstantsList,
  setConstantListParams,
  getConstant,
} = slice.actions;
