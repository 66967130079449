import { createSlice } from '@reduxjs/toolkit';
import { GradeState } from 'src/@types/grades';

const initialState: GradeState = {
  isLoading: false,
  gradesList: {},
  gradesListParams: {},
  grade: null,
};

const slice = createSlice({
  name: 'grades',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    resetError(state) {
      state.error = undefined;
    },

    getGradesList(state, action) {
      state.isLoading = false;
      state.gradesList = action.payload;
    },

    getGrade(state, action) {
      state.isLoading = false;
      state.grade = action.payload;
    },

    setGradesListParams(state, action) {
      state.gradesListParams = action.payload;
    },

    clearGrade(state) {
      state.grade = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  hasError,
  resetError,
  getGradesList,
  setGradesListParams,
  getGrade,
  clearGrade,
} = slice.actions;
