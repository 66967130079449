import { format } from 'date-fns';

import {
  getAnalyticsAppList,
  getAnalyticsPathList,
  getAnalyticsServiceList,
  hasError,
  startLoading,
} from '../slices/analytics';
import {
  AnalyticsAppList,
  AnalyticsListAppParams,
  AnalyticsListServiceParams,
  AnalyticsPathList,
  AnalyticsPathParams,
  AnalyticsServiceList,
} from 'src/@types/analytics';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { DateInterval } from 'src/@types/common';
import { dispatch } from '../store';
import ApiClients from '../../utils/axios';

const { axiosAnalytics } = ApiClients;

export function getAnalyticsAppListThunk(date: DateInterval, params: AnalyticsListAppParams) {
  return async () => {
    dispatch(startLoading());

    try {
      const response: { data: AnalyticsAppList } = await axiosAnalytics.get('application/events', {
        params: {
          from: format(new Date(date.paid_at_from), 'yyyy-MM-dd'),
          to: format(new Date(date.paid_at_to), 'yyyy-MM-dd'),
          ...params,
        },
      });

      dispatch(getAnalyticsAppList(response.data.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getAnalyticsServiceListThunk(
  date: DateInterval,
  params: AnalyticsListServiceParams
) {
  return async () => {
    dispatch(startLoading());

    try {
      const response: { data: AnalyticsServiceList } = await axiosAnalytics.get(
        'service/counters',
        {
          params: {
            ...params,
            from: format(new Date(date.paid_at_from), 'yyyy-MM-dd'),
            to: format(new Date(date.paid_at_to), 'yyyy-MM-dd'),
            grouping: params.grouping ? params.grouping.join(',') : undefined,
          },
        }
      );

      dispatch(getAnalyticsServiceList(response.data.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export const getAdministrationHistoryListThunk = createAsyncThunk(
  'analytics/getAdministrationHistoryList',
  async (
    {
      date: { from, to },
      params,
    }: { date: { from: Date; to: Date }; params: AnalyticsListServiceParams },
    { rejectWithValue }
  ) => {
    try {
      const response: { data: AnalyticsServiceList } = await axiosAnalytics.get(
        'service/admin/counters',
        {
          params: {
            ...params,
            from: format(new Date(from), 'yyyy-MM-dd'),
            to: format(new Date(to), 'yyyy-MM-dd'),
          },
        }
      );

      return response.data.data;
    } catch (error) {
      dispatch(hasError(error));

      return rejectWithValue(error);
    }
  }
);

export function getAnalyticsPathListThunk(date: DateInterval, params: AnalyticsPathParams) {
  return async () => {
    dispatch(startLoading());
    try {
      const response: { data: AnalyticsPathList } = await axiosAnalytics.get('service/counters', {
        params: {
          from: format(new Date(date.paid_at_from), 'yyyy-MM-dd'),
          to: format(new Date(date.paid_at_to), 'yyyy-MM-dd'),
          grouping: 'path',
          ...params,
        },
      });
      
      dispatch(getAnalyticsPathList(response.data.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}
