import React, { ReactNode, createContext } from 'react';
import { createContextualCan, useAbility as useCaslAbility } from '@casl/react';
import { Actions, Subjects } from 'src/@types/ability';
import { createMongoAbility } from '@casl/ability';

const ability = createMongoAbility<[Actions, Subjects]>([]);

export const AbilityContext = createContext(ability);
export const Can = createContextualCan(AbilityContext.Consumer);

export const useAbility = (): any => useCaslAbility(AbilityContext);

export const AbilityProvider = ({ children }: { children: ReactNode }) => (
  <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>
);
