import { createSlice } from '@reduxjs/toolkit';
import { SocialsState } from 'src/@types/socials';

const initialState: SocialsState = {
  isLoading: false,
  socialsList: {},
  socialsListParams: {},
  socials: null,
};

const slice = createSlice({
  name: 'socials',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    resetError(state) {
      state.error = undefined;
    },

    getSocialsList(state, action) {
      state.isLoading = false;
      state.socialsList = action.payload;
    },

    setSocialsListParams(state, action) {
      state.socialsListParams = action.payload;
    },
    getSocials(state, action) {
      state.isLoading = false;
      state.socials = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  hasError,
  resetError,
  getSocialsList,
  setSocialsListParams,
  getSocials,
} = slice.actions;
