import { createSlice } from '@reduxjs/toolkit';
import { NotificationState } from 'src/@types/notification';

const initialState: NotificationState = {
  isLoading: false,
  notifications: {},
  notificationsListParams: {},
};

const slice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    resetError(state) {
      state.error = undefined;
    },

    getNotificationList(state, action) {
      state.isLoading = false;
      state.notifications = action.payload;
    },
    setNotificationListParams(state, action) {
      state.notificationsListParams = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  hasError,
  resetError,
  getNotificationList,
  setNotificationListParams,
} = slice.actions;
