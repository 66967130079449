import { UserRoleBack } from 'src/@types/user';
import {
  navConfigGuest,
  navConfigAdmin,
  navConfigSupport,
  navConfigModerator,
} from 'src/layouts/dashboard/navbar/NavConfig';

export const getPermissionsByRole = (role: UserRoleBack[] | null) => {
  if (!role?.length)
    return [
      {
        action: ['read', 'update'],
        subject: 'guest',
        fields: navConfigGuest,
      },
    ];

  switch (role[0].name) {
    case 'admin':
      return [
        {
          action: ['read', 'update', 'create', 'delete'],
          subject: 'admin',
          fields: navConfigAdmin,
        },
      ];
    case 'moderator':
      return [
        {
          action: ['read', 'update', 'create', 'delete'],
          subject: 'moderator',
          fields: navConfigModerator,
        },
      ];
    case 'support':
      return [
        {
          action: ['read', 'create'],
          subject: 'support',
          fields: navConfigSupport,
        },
      ];
    default:
      return [
        {
          action: ['read', 'update'],
          subject: 'guest',
          fields: navConfigGuest,
        },
      ];
  }
};
