import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';

interface TinyMCEEditorProps {
  value: string;
  onChange: (value: string) => void;
}

export default function TinyMCEEditor({ value, onChange }: TinyMCEEditorProps) {
  const editorRef = useRef<Editor>(null);

  const handleEditorChange = (e: any) => {
    onChange(e.target.getContent());
  };

  const handleImageUpload = (input: HTMLInputElement, callback: any) => {
    if (input.files) {
      const file = input.files[0];
      const reader = new FileReader();
      reader.onload = function () {
        const id = 'blobid' + new Date().getTime();
        const blobCache = editorRef.current?.editor?.editorUpload.blobCache;
        const base64 = reader.result?.toString().split(',')[1];
        const blobInfo = blobCache?.create(id, file, base64 ? base64 : '');
        if (blobInfo !== undefined) {
          blobCache?.add(blobInfo);
          callback(blobInfo.blobUri(), { title: file.name });
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleOpenImageInput = (callback: any, value: any, meta: any) => {
    const input = document.createElement('input') as HTMLInputElement;
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.onchange = (e: Event) => handleImageUpload(e.target as HTMLInputElement, callback);
    input.click();
  };

  return (
    <>
      <Editor
        ref={editorRef}
        apiKey="7p1lbv6wf3jnwegc2mfrj4qlj2aanqpi1ivph06x5wrf60ai"
        initialValue={value}
        init={{
          height: 500,
          menubar: true,
          plugins: [
            'advlist',
            'autolink',
            'lists',
            'link',
            'image',
            'charmap',
            'preview',
            'anchor',
            'help',
            'searchreplace',
            'visualblocks',
            'code',
            'insertdatetime',
            'media',
            'table',
            'lineheight',
          ],
          toolbar:
            'undo redo | formatselect | bold italic | lineheightselect | blocks fontfamily | fontsizeinput | remove | selectall | \
                      alignleft aligncenter alignright | \
                      bullist numlist outdent indent | help',
          language: 'ru',
          font_size_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt',
          line_height_formats: '12px 16px 20px 24px 30px 32px 40px',
          file_picker_types: 'image',
          file_picker_callback: handleOpenImageInput,
          content_style: 'body { font-family: Inter; }',
          style_formats: [
            { title: 'Жирность500', inline: 'span', styles: { fontWeight: '500' } },
            { title: 'Жирность600', inline: 'span', styles: { fontWeight: '600' } },
            { title: 'Жирность700', inline: 'span', styles: { fontWeight: '700' } },
            { title: 'Жирность800', inline: 'span', styles: { fontWeight: '800' } },
            {
              title: 'Основное описание о тренере',
              selector: '*',
              inline: 'span',
              classes: 'mainDescription',
            },
            {
              title: 'Дополнительное описание о тренере',
              block: 'div',
              classes: 'moreDescription',
              wrapper: true,
            },
          ],
        }}
        onChange={handleEditorChange}
      />
    </>
  );
}
