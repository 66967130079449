import { createSlice } from '@reduxjs/toolkit';
import { VersionState } from 'src/@types/version';

const initialState: VersionState = {
  isLoading: false,
  versionList: {},
  versionListParams: {},
  version: null,
};

const slice = createSlice({
  name: 'version',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    resetError(state) {
      state.error = undefined;
    },

    getVersionList(state, action) {
      state.isLoading = false;
      state.versionList = action.payload;
    },

    getVersion(state, action) {
      state.isLoading = false;
      state.version = action.payload;
    },

    setVersionListParams(state, action) {
      state.versionListParams = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  hasError,
  resetError,
  getVersionList,
  setVersionListParams,
  getVersion,
} = slice.actions;
