import { m, AnimatePresence } from 'framer-motion';
// @mui
import { List } from '@mui/material';
import { getFileData } from '../../../utils/getFileData';
// type
import { CustomFile, UploadMultiFileProps } from '../type';
import EmptyFile from './EmptyFile';
import ImageFile from './ImageFile';
import { Fragment } from 'react';
import { FORMATS_IMG } from 'src/@types/common';

// ----------------------------------------------------------------------

export default function MultiFilePreview({
  showPreview = false,
  files,
  onRemove,
  onSetCrop,
}: UploadMultiFileProps) {
  const hasFile = files.length > 0;

  const getFormatFile = (file: CustomFile | string) => {
    let fileFormat: string | undefined = '';

    if (typeof file === 'object') {
      if ('path' in file) fileFormat = file?.path?.slice(file?.path?.lastIndexOf('.') + 1);
      else {
        fileFormat = file.name.slice(file.name.lastIndexOf('.') + 1);
      }
    } else if (typeof file == 'string') {
      fileFormat = file.slice(file.lastIndexOf('.') + 1);
    }

    if (typeof file === 'string') return 'loading';
    if (typeof file === 'object' && FORMATS_IMG.includes(fileFormat || '')) return 'image';
    if (!(typeof file === 'object' && FORMATS_IMG.includes(fileFormat || ''))) return 'other';
  };

  return (
    <List disablePadding sx={{ ...(hasFile && { my: 3 }) }}>
      <AnimatePresence>
        {files.map((file, index) => {
          const { key, name, size, preview } = getFileData(file, index);

          return (
            <Fragment key={`${key}-${name}`}>
              {getFormatFile(file) === 'loading' && <></>}

              {getFormatFile(file) === 'other' && (
                <EmptyFile
                  size={size}
                  name={name}
                  file={file}
                  onRemove={onRemove}
                  showPreview={showPreview}
                  preview={preview}
                />
              )}

              {getFormatFile(file) === 'image' && (
                <ImageFile
                  file={file}
                  onSetCrop={onSetCrop}
                  onRemove={onRemove}
                  aspectRatio={682 / 1000}
                />
              )}
            </Fragment>
          );
        })}
      </AnimatePresence>
    </List>
  );
}
