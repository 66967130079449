import { createSlice } from '@reduxjs/toolkit';
import { AgentState } from 'src/@types/agent';

const initialState: AgentState = {
  isLoading: false,
  agentTypesList: null,
  agentList: {},
  agentListParams: {},
  agent: null,
};

const slice = createSlice({
  name: 'agent',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    resetError(state) {
      state.error = undefined;
    },

    getAgentList(state, action) {
      state.isLoading = false;
      state.agentList = action.payload;
    },
    getAgentTypesList(state, action) {
      state.isLoading = false;
      state.agentTypesList = action.payload;
    },
    getAgent(state, action) {
      state.isLoading = false;
      state.agent = action.payload;
    },

    setAgentListParams(state, action) {
      state.agentListParams = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  hasError,
  resetError,
  getAgentList,
  setAgentListParams,
  getAgent,
  getAgentTypesList,
} = slice.actions;
