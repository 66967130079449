import * as Yup from 'yup';
import { useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack, Container } from '@mui/material';
// utils
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import { FormProvider, RHFSelect, RHFTextField } from '../../components/hook-form';
import useSettings from '../../hooks/useSettings';
import { RootState, useDispatch, useSelector } from '../../redux/store';
import { resetError } from '../../redux/slices/constant';
import { Constant, ParamsConstantForForm, TYPES } from 'src/@types/constant';
import { createConstantThunk, editConstantThunk } from 'src/redux/thunks/constant';
import TextEditor from 'src/components/TextEditor';

// ----------------------------------------------------------------------

interface FormValuesProps extends ParamsConstantForForm {}

interface Props {
  isEdit: boolean;
}

export default function ConstantNewEditForm({ isEdit }: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { themeStretch } = useSettings();
  const { error, constant } = useSelector((state: RootState) => state.constant);
  const [typeRedactor, setTypeRedactor] = useState<Constant['type']>('list');
  const { enqueueSnackbar } = useSnackbar();

  const constantSchema = Yup.object().shape({
    key: Yup.string().required('Название обязательно к заполнению'),
    type: Yup.string().required('Текст обязателен к заполнению'),
    value: Yup.string().required('Ссылка обязательна к заполнению'),
  });

  const defaultValues = useMemo(
    () => ({
      key: constant?.key || '',
      type: constant?.type || 'list',
      value: constant?.value || '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [constant]
  );

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, constant]);

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(constantSchema),
    defaultValues,
  });

  const {
    reset,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const isHtmlType = typeRedactor === 'html';

  const isListType = typeRedactor === 'list';

  useEffect(() => {
    if (error) {
      const objErrors = error.response?.data?.errors;
      if (objErrors) {
        for (const err in objErrors) {
          enqueueSnackbar(objErrors[err], { variant: 'error' });
        }
        dispatch(resetError());
      } else {
        enqueueSnackbar(isEdit ? 'Ошибка при обновлении' : 'Ошибка при создании', {
          variant: 'error',
        });
        dispatch(resetError());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const setContent = (value: string) => {
    setValue('value', value);
  };

  const onSubmit = async (data: FormValuesProps) => {
    const dataParams = {
      ...data,
      type: typeRedactor,
    };
    const result = isEdit
      ? await dispatch(editConstantThunk(Number(constant?.id) || 0, dataParams))
      : await dispatch(createConstantThunk(dataParams));

    if (result) {
      reset();
      enqueueSnackbar(!isEdit ? 'Константа создана' : 'Константа обновлена');
      navigate(PATH_DASHBOARD.constant.root);
    }
  };

  return (
    <Container maxWidth={themeStretch ? false : 'lg'}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Card sx={{ p: 3 }}>
              <Box
                sx={{
                  display: 'grid',
                  columnGap: 2,
                  rowGap: 3,
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
                }}
              >
                <RHFTextField name="key" label="Название" />

                <RHFSelect
                  onChange={(val) => setTypeRedactor(val.target.value as Constant['type'])}
                  name=""
                  label="Тип"
                >
                  <option value={typeRedactor}>{''}</option>
                  {TYPES.map(({ option, label }) => (
                    <option key={option} value={label}>
                      {label}
                    </option>
                  ))}
                </RHFSelect>

                {isListType && <RHFTextField name="value" label="Значение" />}

                {isHtmlType && (
                  <TextEditor value={constant ? constant.value : ''} onChange={setContent} />
                )}
              </Box>
            </Card>
            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                {isEdit ? 'Изменить Константу' : 'Создать константу'}
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </FormProvider>
    </Container>
  );
}
