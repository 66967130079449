import React, { useEffect } from 'react';
import Page from './Page';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Button, Stack, Typography } from '@mui/material';
import { RootState, useDispatch, useSelector } from 'src/redux/store';
import { getFaqListThunk } from 'src/redux/thunks/course';
import { clearFaqList } from 'src/redux/slices/course';
import { PATH_DASHBOARD } from 'src/routes/paths';
import Iconify from './Iconify';
import { Can } from './Ability';

const FaqCourse = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { faqList, faqListParams } = useSelector((state: RootState) => state.course);

  useEffect(() => {
    dispatch(getFaqListThunk({ ...faqListParams, course_id: Number(id) }));
  }, [faqListParams]);

  useEffect(
    () => () => {
      dispatch(clearFaqList());
    },
    []
  );

  return (
    <Page title="FaqCourse">
      <Typography variant="h4" marginBottom="30px">
        Часто задаваемые вопросы
      </Typography>

      <Stack direction="row" marginBottom="20px" columnGap="15px">
        {!Boolean(faqList?.data?.length) && (
          <Typography variant="h5">Список вопросов пуст</Typography>
        )}
        <Can I="update" a="admin">
          <Button
            variant="contained"
            component={RouterLink}
            state={`faqCourse/${id}`}
            to={PATH_DASHBOARD.faq.new}
            startIcon={<Iconify icon={'eva:plus-fill'} />}
          >
            Faq
          </Button>
        </Can>
      </Stack>

      {faqList?.data && (
        <>
          <Stack rowGap="25px">
            {faqList.data.map((faq, index) => (
              <div key={faq.id}>
                <Typography variant="h5">{`${index + 1}. ${faq.question}`}</Typography>
                <Typography variant="h6" color="#B5B5B4">
                  {faq.answer}
                </Typography>
              </div>
            ))}
          </Stack>
        </>
      )}
    </Page>
  );
};

export default FaqCourse;
