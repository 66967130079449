import { dispatch } from '../store';
import ApiClients from '../../utils/axios';
import { getConstantsList, startLoading, hasError, getConstant } from '../slices/constant';
import { ConstantList, ConstantListParams, ParamsConstantForForm } from 'src/@types/constant';

const { axiosApp } = ApiClients;

export function getConstantListThunk(params: ConstantListParams) {
  return async () => {
    dispatch(startLoading());
    try {
      const response: { data: ConstantList } = await axiosApp.get('constants', { params });
      dispatch(getConstantsList(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getConstantThunk(id: number) {
  return async () => {
    dispatch(startLoading());
    try {
      const response: { data: ConstantList } = await axiosApp.get(`constants/${id}`);
      dispatch(getConstant(response.data.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function editConstantThunk(id: number, params: ParamsConstantForForm) {
  return async () => {
    dispatch(startLoading());
    try {
      await axiosApp.put(`constants/${id}`, {
        ...params,
      });

      return true;
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function createConstantThunk(params: ParamsConstantForForm) {
  return async () => {
    dispatch(startLoading());
    try {
      await axiosApp.post(`constants`, {
        ...params,
      });

      return true;
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function deleteConstantThunk(id: number) {
  return async () => {
    dispatch(startLoading());
    try {
      await axiosApp.delete(`constants/${id}`);

      dispatch(getConstantListThunk({}));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}
