import { createSlice } from '@reduxjs/toolkit';
import { StoryState } from 'src/@types/story';

const initialState: StoryState = {
  isLoading: false,
  storyList: {},
  storyListParams: {},
  story: null,
};

const slice = createSlice({
  name: 'story',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    resetError(state) {
      state.error = undefined;
    },

    getStoryList(state, action) {
      state.isLoading = false;
      state.storyList = action.payload;
    },

    getStory(state, action) {
      state.isLoading = false;
      state.story = action.payload;
    },

    setStoryListParams(state, action) {
      state.storyListParams = action.payload;
    },

    clearStory(state) {
      state.story = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  hasError,
  resetError,
  getStoryList,
  setStoryListParams,
  getStory,
  clearStory,
} = slice.actions;
