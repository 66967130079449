import { createSlice } from '@reduxjs/toolkit';
import { TrainerState } from '../../@types/trainer';

const initialState: TrainerState = {
  isLoading: false,
  trainerList: {},
  trainerListParams: {},
};

const slice = createSlice({
  name: 'trainer',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    resetError(state) {
      state.error = undefined;
    },

    getTrainerListSuccess(state, action) {
      state.isLoading = false;
      state.trainerList = action.payload;
    },

    getTrainerSuccess(state, action) {
      state.isLoading = false;
      state.trainer = action.payload;
    },

    setTrainerListParams(state, action){
      state.trainerListParams = action.payload;
    }
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading, hasError, resetError, getTrainerListSuccess, getTrainerSuccess, setTrainerListParams
} = slice.actions;

