import { useFormContext, Controller } from 'react-hook-form';
import { TextFieldProps } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';

type RHFDatePickerType = {
  name: string;
};

type RHFDatePickerProps = RHFDatePickerType & TextFieldProps;

const RHFDatePicker = ({ name, label }: RHFDatePickerProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <DateTimePicker
          label={label}
          format="dd.MM.yyyy HH:mm"
          value={field.value ?? null}
          viewRenderers={{
            hours: null,
            minutes: null,
            seconds: null,
          }}
          onChange={(v) => {
            field.onChange(v);
          }}
          slotProps={{
            textField: {
              error: !!error,
              helperText: error?.message,
            },
          }}
        />
      )}
    />
  );
};

export default RHFDatePicker;
