import { AxiosResponse } from 'axios';

import {
  ApproveCommentRequest,
  Comment,
  CommentGroup,
  CommentListParams,
  ParamsCommentChange,
  ParamsCommentCreate,
} from 'src/@types/comment';
import { Pagination } from 'src/@types/common';
import { HOST_COMMENTS } from 'src/config';
import $api from '../axios';

const getCommentList = (params: CommentListParams) =>
  $api.get<Comment[], AxiosResponse<{ data: Comment[]; meta: Pagination }>>(
    HOST_COMMENTS + `/comments`,
    { params }
  );

const getCommentById = (id: number) =>
  $api.get<{ data: Comment }>(HOST_COMMENTS + `/comments/${id}`);

const editComment = ({ id, ...params }: ParamsCommentChange) =>
  $api.put<{ data: Comment }>(HOST_COMMENTS + `/comments/${id}`, params);

const createComment = (params: ParamsCommentCreate) =>
  $api.post<{ data: Comment }>(HOST_COMMENTS + `/comments`, params);

const changeCommentStatus = ({ ids, status }: ApproveCommentRequest) =>
  $api.put<{ data: Comment[] }>(HOST_COMMENTS + `/comments-bulk`, { ids, status });

const deleteComment = (ids: number[]) =>
  $api.delete<{ data: number }>(HOST_COMMENTS + `/comments-bulk`, { data: { ids } });

const getCommentGroups = () => $api.get<{ data: CommentGroup[] }>(HOST_COMMENTS + `/groups`);

export const CommentServices = {
  getCommentList,
  getCommentById,
  createComment,
  editComment,
  changeCommentStatus,
  deleteComment,
  getCommentGroups,
};
